import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { page } from "components/page";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import Captcha from "components/common/Captcha";
import { ApolloProvider, Mutation } from "react-apollo";
import { Interpolate } from "react-i18next";

axios.defaults.headers.common = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

const setLogin = gql`
  mutation setLogin($form: LoginForm) {
    result: setLogin(Input: $form) {
      success
      errorType
      sessionID
    }
  }
`;

@withI18next(["common"])
@page
class LoginPage2 extends React.Component {
  constructor(props) {
    super(props);
    let hasCaptcha =
      props.appStore.globalConfig["hyint.module.isCaptcha"] !== "0";
    let alertHidden =
      props.appStore.globalConfig["jumperrwd.login.alertHidden"] === "1";
    let errPassMaxString =
      props.appStore.globalConfig["hyint.login.errPassMax"];
    let errPassDurationString =
      props.appStore.globalConfig["hyint.login.errPassDuration"];
    let errPassMax = 3;
    if (errPassMaxString !== "") {
      errPassMax = parseInt(errPassMaxString);
    }
    let errPassDuration = 10;
    if (errPassDurationString !== "") {
      errPassDuration = parseInt(errPassDurationString);
    }
    let captchaAudio = props.appStore.globalConfig["jumperrwd.captcha.useAudio"] !== "0";
    let languageShowType = props.appStore.globalConfig["jumperrwd.loginPage.languageShowType"] || "";
    if (languageShowType === "") {
      languageShowType = "1";
    }
    this.state = {
      user: "",
      pass: "",
      captcha: "",
      loginMessage: "",
      loginDisable: false,
      processLogin: false,
      captchRefetch: null,
      alertHidden: alertHidden,
      hasCaptcha: hasCaptcha,
      errPassMax: errPassMax,
      errPassDuration: errPassDuration,
      captchaAudio: captchaAudio,
      languageShowType: languageShowType,
      showLanguageChange: false,
    };
  }

  componentDidMount() {
    let { search } = this.props;
    let params = { ...search };
    let { lang } = params;
    if (lang === "cht") {
      this.handleChangeLanguage("zh");
    } else if (lang === "eng") {
      this.handleChangeLanguage("en");
    }
  }

  handleChangeLanguage(lng) {
    axios
      .get("/api/jumperrwdWs/setSession?key=locales&value=" + lng)
      .then((res) => {});
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  @autobind
  loginSubmit(setLogin, e) {
    e.preventDefault();
    let { hasCaptcha } = this.state;

    if (
      this.state.user !== "" &&
      this.state.pass !== "" &&
      (!hasCaptcha || (hasCaptcha && this.state.captcha !== ""))
    ) {
      this.setState({
        loginDisable: true,
        processLogin: true,
      });
      setLogin({
        variables: {
          form: {
            user: this.state.user,
            pass: this.state.pass,
            captcha: this.state.captcha,
            userType: "",
            loginPage: "login2"
          },
        },
      });
    } else if (this.state.user === "") {
      alert(this.props.t("jumperrwd.login.pleaseEnterUsername"));
    } else if (this.state.pass === "") {
      alert(this.props.t("jumperrwd.login.pleaseEnterPwd"));
    } else if (hasCaptcha && this.state.captcha === "") {
      alert(this.props.t("jumperrwd.common.pleaseEnterCaptcha"));
    }
  }

  @autobind
  async handleLogin(data) {
    if (data.result.success) {
      await this.props.readerStore.syncSessionCookie().then(() => {
        if (!this.state.alertHidden) {
          alert(this.props.t("jumperrwd.login.loginSuccess"));
        }
        if (this.props.appStore.loginRedirect !== null) {
          navigate(this.props.appStore.loginRedirect);
        } else {
          navigate("/");
        }
      });
    } else {
      if (data.result.errorType === 1) {
        alert(this.props.t("jumperrwd.common.captchaError"));
        if (this.state.captchRefetch) {
          this.state.captchRefetch();
        }
      } else if (data.result.errorType === 2) {
        alert(
          this.props.t("jumperrwd.login.errPass", {
            errPassMax: this.state.errPassMax.toString(),
            errPassDuration: this.state.errPassDuration.toString(),
          })
        );
        if (this.state.captchRefetch) {
          this.state.captchRefetch();
        }
      } else {
        alert(this.props.t("jumperrwd.login.loginFailed"));
        if (this.state.captchRefetch) {
          this.state.captchRefetch();
        }
      }
    }
    this.setState({ loginDisable: false, processLogin: false });
  }

  @autobind
  handleUser(e) {
    this.setState({
      user: e.target.value,
    });
  }

  @autobind
  handlePass(e) {
    this.setState({
      pass: e.target.value,
    });
  }

  @autobind
  handleCaptcha(e) {
    this.setState({
      captcha: e.target.value,
    });
  }

  @autobind
  toLib(e) {
    e.preventDefault();
    window.location.href =
      this.props.appStore.globalConfig["jumperrwd.login.libraryHome"] || "/";
  }

  @autobind
  getCaptchRefetch(refetch) {
    this.setState({ captchRefetch: refetch });
  }

  @autobind
  playCaptcha() {
    if (
      this.captcha &&
      this.captcha.audio &&
      this.captcha.audio.value &&
      this.captcha.audio.value !== ""
    ) {
      let audio = new Audio(
        "data:audio/mpeg;base64," + this.captcha.audio.value
      );
      audio.play();
    }
  }

  render() {
    let { t, i18n, appStore, hasCaptcha } = this.props;
    let { captchaAudio, languageShowType, showLanguageChange } = this.state;
    let { header } = appStore;
    let { languageList } = header;
    let { language } = i18n;
    let tImageSrc = "",
      defaultTSrc = "/file/images/logo.png";
    if (language === "zh") {
      tImageSrc = "/file/images/logo.png";
    } else if (language === "en") {
      tImageSrc = "/file/images/logo_en.png";
    }
    if (tImageSrc === "") {
      tImageSrc = defaultTSrc;
    }
    return (
      <Layout {...this.props} isLoginPage={true} hiddenBg={true}>
        <div className="main login_page" id="center">
          <section>
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <div
                className="login_lightbox"
                ref="login_lightbox"
                style={{
                  opacity: 1,
                  transition: "opacity 1s",
                  display: "block",
                }}>
                <div className="overlay">
                  <img src="/file/images/login_bg.jpg" alt="背景圖片"></img>
                </div>
                <div className="loginblock">
                  <div className="main_img">
                    <h1 className="logo">
                      <img
                        src={tImageSrc}
                        alt={this.props.t("jumper.sys.name") + "Logo"}
                      />
                    </h1>
                    <div className="textblock">
                      <div className="text_content">
                        {ReactHtmlParser(t("jumperrwd.login.about2"))}
                      </div>
                    </div>
                    <div className="img-container">
                      <img src="/file/images/lib_free.png" alt="內部背景圖" />
                    </div>
                  </div>
                  <div className="login_form">
                    <h2>
                      {t("jumperrwd.common.login")}{" "}
                      <span className="name">{t("jumper.login.libname")}</span>
                    </h2>
                    <div className="login_instructions">
                      <div className="pc">
                        {ReactHtmlParser(t("jumperrwd.login.pcNote2"))}
                      </div>
                      <div className="mobile">
                        {ReactHtmlParser(t("jumperrwd.login.mobileNote2"))}
                      </div>
                    </div>
                    <div className="error">
                      {(() => {
                        if (this.state.processLogin)
                          return (
                            <div style={{ textAlign: "center" }}>
                              {t("jumperrwd.login.processLogin")}
                            </div>
                          );
                        else return "";
                      })()}
                    </div>
                    <form>
                      <div className="form_content">
                        <>
                          {" "}
                          <div className="form_grp form_inline">
                            <label htmlFor="username">
                              {t("jumperrwd.login.username")}
                            </label>
                            <input
                              id="username"
                              name="username"
                              ref={(c) => {
                                this.usernameInput = c;
                              }}
                              type="text"
                              placeholder={t("jumperrwd.login.enterUsername2")}
                              value={this.state.user}
                              onChange={this.handleUser}
                            />
                          </div>
                          <div className="form_grp form_inline">
                            <label htmlFor="password">
                              {t("jumperrwd.login.password")}
                            </label>
                            <input
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="off"
                              placeholder={t("jumperrwd.login.enterPwd2")}
                              value={this.state.pass}
                              onChange={this.handlePass}
                            />
                          </div>
                          <div className="form_grp form_inline">
                            {hasCaptcha && (
                              <>
                                {this.props.display !== "none" && (
                                  <>
                                    <Captcha
                                      ref={(c) => {
                                        this.captcha = c;
                                      }}
                                      typeName="login"
                                      t={this.props.t}
                                      getRefetch={this.getCaptchRefetch}
                                    />
                                    <input
                                      name="captcha"
                                      title={t("jumperrwd.common.captcha")}
                                      type="text"
                                      value={this.state.captcha}
                                      placeholder={t(
                                        "jumperrwd.common.enterCaptcha"
                                      )}
                                      onChange={this.handleCaptcha}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          {hasCaptcha && captchaAudio && (
                            <Interpolate
                              t={t}
                              i18nKey="jumperrwd.captcha.audioInfo"
                              parent={"p"}
                              audioPlay={
                                <a
                                  href="javascript:;"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.playCaptcha();
                                  }}>
                                  {t("jumperrwd.captcha.audioPlay")}
                                </a>
                              }
                            />
                          )}
                        </>
                        <ApolloProvider client={client.jumperrwdClient}>
                          <Mutation
                            mutation={setLogin}
                            onError={() => {
                              this.setState({
                                loginMessage: "jumperrwd.login.loginError",
                              });
                            }}
                            onCompleted={(data) => this.handleLogin(data)}>
                            {(setLogin) => (
                              <div className="btn_grp">
                                <input
                                  className=""
                                  tabIndex="0"
                                  type="submit"
                                  title={t("jumperrwd.common.login")}
                                  value={t("jumperrwd.common.login")}
                                  disabled={this.state.loginDisable}
                                  onClick={this.loginSubmit.bind(
                                    this,
                                    setLogin
                                  )}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      this.loginSubmit(setLogin, e);
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </Mutation>
                        </ApolloProvider>
                      </div>
                    </form>
                    <div className="copyright">
                      {ReactHtmlParser(
                        this.props.t("jumperrwd.login.copyRightContent")
                      )}
                    </div>
                  </div>
                  <div className="function_grp">
                    <button
                      type="button"
                      tabIndex="0"
                      className="btn"
                      onClick={this.toLib.bind(this)}>
                      {this.props.t("jumperrwd.login.backToLibHome")}
                    </button>
                    {languageShowType === "1" &&
                      <div className="language">
                        <select
                          className=""
                          id=""
                          value={this.props.i18n.language}
                          title={this.props.t("jumperrwd.common.language")}
                          onChange={(e) => {
                            e.preventDefault();
                            this.handleChangeLanguage(e.target.value);
                          }}>
                          {languageList.map((language) => {
                            let { code, label } = language;
                            return <option value={code}>{label}</option>;
                          })}
                        </select>
                      </div>
                    }
                    {languageShowType === "2" &&
                      <div
                        className="language dropmenu"
                        ref={(c) => {
                          this.languageMenu = c;
                        }}>
                        <a
                          tabIndex="0"
                          role="button"
                          title={t("jumperrwd.common.showChangeLanguage")}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              showLanguageChange: !showLanguageChange,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.setState({
                                showLanguageChange: !showLanguageChange,
                              });
                            }
                          }}>
                          {t("jumper.common.selectlang")}
                        </a>
                        {showLanguageChange && (
                          <ul
                            style={{
                              display: showLanguageChange ? "block" : "none",
                            }}>
                            {languageList.map((language) => {
                              let { code, label } = language;
                              return (
                                <li>
                                  <a
                                    tabIndex="0"
                                    title={"語言變更為" + label}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleChangeLanguage(code);
                                      this.setState({ showLanguageChange: false });
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        this.handleChangeLanguage(code);
                                        this.setState({ showLanguageChange: false });
                                      }
                                    }}>
                                    {label}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default LoginPage2;
